<template>
    <div id="scrolltrigger">
        <div class="letter">
            <div class="letter-container">
                <div class="letter-paper">
                    <div class="letter-paper-before">- {{lastLine}}</div>
                    <p>
                        <span v-for="line, index in list" :key="index">- {{line}} <br></span>
                        <span v-for="line, index in list" :key="index">- {{line}} <br></span>
                    </p>
                </div>
            </div>
            <div class="envelope"></div>
        </div>
    </div>
    <div class="intro">
        <div class="intro-text">
            Cher Père Noël, <br>
            Cette année encore, nous avons été particulièrement sage. <br>
            Nous avons fait (presque) tout ce que nous ont demandé les chef.fes de projets. <br>
            Nous avons partagé du miel avec amour. <br>
            Nous avons révisé notre langue française, notre géographie et notre culture générale avec assiduité et enthousiasme. <br>
            Nous avons été rigoureux sur le sport, que ce soit lors des sprints ou quand il fallait montrer notre agilité. <br>
            En somme, nous avons été exemplaire. <br>
            Cependant, comme on te sait très chargé puisque tu as beaucoup d'enfants à gâter, nous nous sommes contentés d'une modeste liste plutôt réalisable afin de faciliter ton travail :
        </div>
        <div class="intro-button">
            <div class="button" @click="launch">Lire la lettre des Devs</div>
        </div>
    </div>
    <Snow />
    <Song />
</template>

<script>
import Snow from './components/Snow.vue'
import Song from './components/Song.vue'

import gsap from "gsap"

export default {
    name: 'App',
    components: {
        Snow,
        Song
    },
    data() {
        return {
            launchTl: null,
            list: [
                "Des fenêtres dans nos bureaux",
                "Le droit de nanotape les cadeaux au sol",
                "Du code propre et performant",
                "La victoire au challenge de Noël",
                "Que ce ne soit pas l'anniv de Ben tous les jours",
                "Que les fronts aient moins besoin des backs",
                "La refonte de oubruncher et newtable en symfo",
                "Le droit de se balader en chaussettes au sein de l'agence",
                "Qu'Alexandra vienne manger au domac ou un kebab avec nous quand on lui propose",
                "Semaine de 4 jours",
                "Des bureaux individuels et excentrés de Paris",
                "Pouvoir cocher nos taches terminées dans Furious",
                "Un séminaire tous les ans",
                "Un séminaire en VR pour les gens en remote ou ceux qui ont des mariages",
                "Des salles de réu dispo",
                "Qu’on puisse assister au codir et que notre voie soit entendue",
                "Plus de 2 verres offerts aux after work",
                "QUE BEN RESTE !!!",
                "Ne pas donner les brunches qu’à Aurélien",
                "Une PS5",
            ]
        }
    },
    computed: {
        lastLine() {
            let last = this.list.slice(-1)

            return last[0]
        }
    },
    mounted() {
        let lPaper = document.querySelector('.letter-paper'),
            scrollTl = gsap.timeline({
                paused: true,
                repeat: -1
            }),
            offset = ((-lPaper.offsetHeight + 48) / 2)

        if (window.outerWidth < 780) {
            offset -= 75
        }

        scrollTl.fromTo(lPaper, {
            y: 0
        }, {
            y: offset,
            ease: 'none',
            duration: 15
        })

        let launchTl = gsap.timeline({paused: true, onComplete: function() {
            scrollTl.play()
        }})

        launchTl.to('.intro', {
            opacity: 0,
            display: 'none'
        })

        launchTl.to('.letter', {
            y: 0,
            duration: 1.6,
            ease: 'back'
        })

        launchTl.from('.letter-container', {
            height: '25%',
            top: '75%',
            duration: 1
        })

        this.launchTl = launchTl
    },
    methods: {
        launch() {
            Song.methods.play()

            this.launchTl.play()
        }
    }
}
</script>

<style lang="scss">
    :root {
        --red: #c70000;
        --lighter-red: #F5624D;
        --green: #0F8A5F;
    }

    body {
        height: 100vh;

        margin: 0;

        background: radial-gradient(ellipse at bottom, var(--lighter-red) 0%, var(--red) 100%);
        background-attachment: fixed;

        font-family: 'Pacifico', cursive;
    }

    .intro {
        position: fixed;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        font-size: 24px;

        &-text {
            color: #fff;
        }

        &-button {
            text-align: center;
        }

        @media screen and (max-width: 780px) {
            top: 0;
            left: 0;

            width: calc(100% - 30px);
            height: calc(100% - 30px);

            font-size: 22px;

            padding: 15px;

            overflow: auto;

            transform: none;

            background-color: rgba(#000, .25);
        }
    }

    .button {
        display: inline-block;

        color: var(--green);
        font-size: 24px;

        margin: {
            top: 40px;
        }
        padding: 24px 36px;

        background-color: #fff;
        border-radius: 99px;

        cursor: pointer;

        @media screen and (max-width: 780px) {
            margin: {
                top: 20px;
            }
        }
    }

    .letter{
        /* display: none; */

        transform: translateY(100%);

        position: fixed;
        inset: 0;
        margin: auto;

        width: 50%;

        aspect-ratio: 380 / 450;

        margin: 100px auto;

        &:before{
            position: absolute;
            width: 100%;
            bottom: 26%;
            height: 46%;
            background-image: conic-gradient(at 50% 0, transparent 122deg, #fff 123deg 238deg, transparent 235deg);
            filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
            content: '';
        }

        @media screen and (max-width: 780px) {
            width: calc(100% - 30px);

            transform: translateY(300%);
        }
    }
    .letter-container {
        position: fixed;
        top: 0;
        bottom: 75%;

        width: 100%;
        height: 75%;

        overflow-y: hidden;
    }
    .letter-paper{
        $zero: 0px;

        margin-top: 50%;

        position: relative;
        // padding: 5px 0 30% 35px;
        padding: 5px 0 5px 35px;
        width: calc(100% - 30px);
        // height: 400px;
        margin: 0 auto;
        color: var(--green);
        font-size: 18px;
        line-height: 40px;
        background-image:
        repeating-linear-gradient(transparent calc(#{$zero}) 39px, #999 39px 40px,transparent 40px),
        linear-gradient(#eee,#edecee);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center -15px, 0 0;
        box-shadow: 0 0 5px 2px rgba(0,0,0,.2);
        box-sizing: border-box;

        @media screen and (max-width: 780px) {
            padding: 5px 0 5px 15px;
        }
    }
    .letter-paper-before {
        position: absolute;
        top: -15px;
        left: 35px;

        @media screen and (max-width: 780px) {
            left: 15px;
        }
    }
    .envelope{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 46%;
        background-image: repeating-linear-gradient(45deg,transparent 0 10px,var(--red) 10px 20px,transparent 20px 30px,var(--green) 30px 40px),repeating-linear-gradient(-45deg,transparent 0 10px,var(--red) 10px 20px,transparent 20px 30px,var(--green) 30px 40px),conic-gradient(transparent 61deg, #fff 61deg 299deg, transparent 299deg);
        background-size: 12px 100%, 12px 100%,100% 100%;
        background-repeat: no-repeat;
        background-position: 0 -8px, right -8px,0 0;
        filter: drop-shadow(0 0 5px rgba(0,0,0,.2));

        pointer-events: none;
        &:after{
            position: absolute;
            width: 100%;
            height: 130%;
            bottom: 0;
            left: 0;
            background-image: repeating-linear-gradient(-45deg, transparent 0 10px, var(--red) 10px 20px, transparent 20px 30px, var(--green) 30px 40px), conic-gradient(transparent 125deg, #fff 126deg 234deg, transparent 235deg);
            background-repeat: no-repeat;
            background-size: 105% 12px,100%;
            background-position: -9px bottom,0 0;
            content: '';
            filter: drop-shadow(0 0 5px rgba(0,0,0,.2));
        }

    }
</style>
