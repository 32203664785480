<template>
    <div class="song">
        <audio controls loop>
            <source src="@/assets/noel.mp3" type="audio/mp3">
        </audio>
    </div>
  </template>

  <script>
  export default {
    name: 'Audio',
    data() {
        return {
            audio: document.querySelector('.song audio')
        }
    },
    methods: {
        play() {
            let audio = document.querySelector('.song audio')

            audio.volume = 0.1;

            if (audio.paused) {
                audio.play()
            }
        }
    }
  }
  </script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    .song {
        display: none;
    }
  </style>
